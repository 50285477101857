import { Injectable } from "@angular/core";
import {
  MigrateSetupSteps,
  RejectInfo,
  ApproveInfo,
  MigratedTask,
} from "src/app/interfaces/process";
import { OnBoardingService } from "./onboarding.service";
import {
  ProcessNames,
  HistoryTask,
  AllTaskKey,
  HistoryProcessInstance,
  WorkflowEndType,
  MigrateProcess,
  SetupProcess,
} from "src/app/interfaces/workflow";
import {
  SupplierStatus,
  RiskAssessmentStatus,
  SapResultModel,
} from "src/app/interfaces/mapping";
import * as moment from "moment";
import { WorkflowService } from "src/app/services/workflow.service";
import { format } from "util";
import { SupplierService } from "src/app/services/supplier.service";
import { DialogType } from "src/app/services/dialog.service";
import { cloneDeep } from "src/app/utils";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Injectable({
  providedIn: "root",
})
export class MigrateSetupProcessService {
  get migrateSetupTaskHistory(): Array<any> {
    if (this.onboardingService.supplierModel.Mapping.historyTasks) {
      return this.onboardingService.supplierModel.Mapping.historyTasks.filter(
        (task) =>
          task.processDefName === ProcessNames.generic_supplierMigrateSetup
      );
    }
  }

  circleSteps = [];
  currTitleStep: any;
  isTerminated = false;
  ssresubmitStateValue = false;
  private migrateSetupSteps = MigrateSetupSteps;
  private rejectInfo = RejectInfo;
  private approveInfo = ApproveInfo;
  private processHistories: HistoryProcessInstance[];
  isNewVersion: boolean = true;

  constructor(
    private onboardingService: OnBoardingService,
    private workflowService: WorkflowService,
    private supplierService: SupplierService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
    console.log("Trigger refresh data")
    this.refreshToggle()
  })}
  refreshToggle():void{
    this.isNewVersion = this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION).toLowerCase() === "true";
  }
  // load supplier setup process
  async loadMigrateSetupProcess() {
    this.migrateSetupSteps = cloneDeep(MigrateSetupSteps);
    if (
      this.onboardingService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
    ) {
      this.isTerminated = true;
    } else {
      // bug 958823
      this.isTerminated = false;
    }
    // update task steps
    await this.updateMigrateSetup_MigrateSteps();
    this.initMigrateSetup_APTasks();
    await this.initMigrateSetup_EndSteps();
    if (this.migrateSetupTaskHistory) {
      this.migrateSetupTaskHistory.forEach((item: HistoryTask) => {
        if (
          item.name === MigrateProcess.supplier_accepted ||
          item.name === MigrateProcess.profile_draft
        ) {
          this.updateMigrateSetup_TaskSteps(item);
        }
      });
    }

    this.migrateSetupSteps_Handling();
  }

  migrateSetupSteps_Handling() {
    // buyer process don't show SAP
    const showSteps = Object.values(this.migrateSetupSteps).filter(
      (item) => item.status !== RiskAssessmentStatus.SAP
    );

    // unfinished task
    const unfinishedTasks = showSteps.filter((item) => !item.done);

    // complete task
    let completedTasks = showSteps.filter((item) => item.done);
    completedTasks = completedTasks.sort(
      (first, second) =>
        moment(first.step.UpdateAt).unix() - moment(second.step.UpdateAt).unix()
    );

    if (this.isTerminated) {
      const terminateTask = completedTasks.find(
        (ct) => ct.status === "Terminated"
      );
      completedTasks = completedTasks.filter(
        (ct) => ct.status !== "Terminated"
      );
      completedTasks.push(terminateTask);
    } else {
      const terminateTask = showSteps.find((ct) => ct.status === "Terminated");
      terminateTask.show = false;
    }
    // all step circles
    this.circleSteps = this.isTerminated
      ? [...completedTasks]
      : [...completedTasks, ...unfinishedTasks];
    //  current step
    if(!this.ssresubmitStateValue){
      this.currTitleStep = Object.assign([], this.circleSteps)
      .reverse()
      .find((item) => !!item && item.done);
    }
      else
      {
        this.currTitleStep = Object.assign([], this.circleSteps)
      .find((item) => !!item && item.status == MigrateProcess.profile_resubmit);
      }
      this.circleSteps = this.circleSteps.filter(((item) => !!item && item.status !== MigrateProcess.profile_resubmit));
    }

  // update migrate steps
  private async updateMigrateSetup_MigrateSteps() {
    const migrateDate = await this.getMigrateDate(
      this.onboardingService.supplierModel.Mapping.supplierCode
    );
    const tmpItem: any = {
      UpdateAt: migrateDate,
      status: MigratedTask.migrate,
    };
    const step = this.migrateSetupSteps[MigratedTask.migrate];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }
  // update general task steps
  private updateMigrateSetup_TaskSteps(task: HistoryTask) {
    const tmpItem: any = {
      UpdateAt: task.endTime,
      status: task.name,
    };
    const step = this.migrateSetupSteps[task.name];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // init terminate and on boarded steps
  private async initMigrateSetup_EndSteps() {
    if (this.isTerminated) {
      const sapResult: SapResultModel[] =
        this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult;
      sapResult.forEach((item: SapResultModel) => {
        if (item.type === DialogType.terminateSupplier) {
          const createdDt: string = moment(item.createdOn).format(
            "DD-MMM-YYYY"
          );
          this.updateMigrateSetup_TerminatedSteps(createdDt);
        }
      });
    }
    await this.loadProcessHistories();
    if (this.processHistories && this.processHistories.length > 0) {
      if (
        this.processHistories.find(
          (item) =>
            item.endType === WorkflowEndType.terminateEnd &&
            item.name !== ProcessNames.generic_buyerChangeRequest
        )
      ) {
        const setupProcess = this.processHistories.find(
          (item) => item.endType === WorkflowEndType.terminateEnd
        );
        this.updateMigrationSupplierSetup_TerminatedSteps(setupProcess);
      }
      const migrateSetupProcess = this.processHistories.find(
        (process: HistoryProcessInstance) =>
          process.name === ProcessNames.generic_supplierMigrateSetup
      );
      if (!migrateSetupProcess) {
        return;
      }
      if (
        migrateSetupProcess.endType &&
        migrateSetupProcess.endType !== WorkflowEndType.terminateEnd
      ) {
        this.updateMigrateSetup_CompletedSteps(migrateSetupProcess);
      }
    }
  }

  // init ap subProcess task steps
  private initMigrateSetup_APTasks() {
    const sstReviewComplete: boolean =
      this.workflowService.checkAPTaskIsComplete(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.SST_review,
        this.migrateSetupTaskHistory
      );
    const sstReviewReject: boolean = this.workflowService.checkAPTaskIsReject(
      ProcessNames.generic_supplierMigrateSetup,
      MigrateProcess.SST_review,
      this.migrateSetupTaskHistory
    );
    const ssresubmitState:boolean = this.workflowService.checkAPTaskPendingReview(
      ProcessNames.generic_supplierMigrateSetup,
      MigrateProcess.SST_rejectToSupplier,
      this.migrateSetupTaskHistory
    );
    this.ssresubmitStateValue = ssresubmitState;
    const qcReviewComplete: boolean =
      this.workflowService.checkAPTaskIsComplete(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.QC_review,
        this.migrateSetupTaskHistory
      );
    const qcReviewReject: boolean = this.workflowService.checkAPTaskIsReject(
      ProcessNames.generic_supplierMigrateSetup,
      MigrateProcess.QC_review,
      this.migrateSetupTaskHistory
    );
    // sst reject
    if (!ssresubmitState){
    if (sstReviewReject) {
      const lastSSTTask = this.getMigrateSetup_SSTTask();
      this.updateMigrateSetup_TaskSteps(lastSSTTask);
      const rejectTo = "supplier";
      // const rejectToSupplier = this.onboardingService.getUserTaskByKey(ProcessNames.generic_supplierMigrateSetup, MigrateProcess.SST_rejectToSupplier)
      const isSSTReject = true;
      const sstIntro = format(this.rejectInfo.SST_Reject_intro, rejectTo);
      const sstBody = format(this.rejectInfo.SST_Reject_body, rejectTo);
      this.updateMigrateSetup_APTaskSteps(
        lastSSTTask,
        isSSTReject,
        sstIntro,
        sstBody
      );
      // sst approve
    } else if (sstReviewComplete) {
      const lastSSTTask = this.getMigrateSetup_SSTTask();
      this.updateMigrateSetup_TaskSteps(lastSSTTask);
      const isSSTReject = false;
      const sstIntro = this.approveInfo.SST_Approve_intro;
      const sstBody = this.approveInfo.SST_Approve_body;
      this.updateMigrateSetup_APTaskSteps(
        lastSSTTask,
        isSSTReject,
        sstIntro,
        sstBody
      );
      // qc reject
      if (qcReviewReject) {
        const lastQCTask = this.getMigrateSetup_QCTask();

        if (
          moment(lastSSTTask.endTime).unix() > moment(lastQCTask.endTime).unix()
        ) {
          return;
        }
        this.updateMigrateSetup_TaskSteps(lastQCTask);
        const isQCReject = true;
        const qcIntro = this.rejectInfo.QC_Reject_intro;
        const qcBody = this.rejectInfo.QC_Reject_body;
        this.updateMigrateSetup_APTaskSteps(
          lastQCTask,
          isQCReject,
          qcIntro,
          qcBody
        );
        // qc approve
      } else if (qcReviewComplete) {
        const lastQCTask = this.getMigrateSetup_QCTask();
        this.updateMigrateSetup_TaskSteps(lastQCTask);
        const qcIntro = this.approveInfo.QC_Approve_intro;
        const qcBody = this.approveInfo.QC_Approve_body;
        const isQCReject = false;
        this.updateMigrateSetup_APTaskSteps(
          lastQCTask,
          isQCReject,
          qcIntro,
          qcBody
        );
      }
    } // termianted when pending AP sst review and AP QC reject  
    else if (qcReviewReject) {
      const lastSSTTask = this.getMigrateSetup_SSTTaskforQCreject();
      this.updateMigrateSetup_TaskSteps(lastSSTTask);
      const isSSTReject = false;
      const sstIntro = this.approveInfo.SST_Approve_intro;
      const sstBody = this.approveInfo.SST_Approve_body;
      this.updateMigrateSetup_APTaskSteps(
        lastSSTTask,
        isSSTReject,
        sstIntro,
        sstBody
      );
      // qc reject

      const lastQCTask = this.getMigrateSetup_QCTask();
      if (
        moment(lastSSTTask.endTime).unix() > moment(lastQCTask.endTime).unix()
      ) {
        return;
      }
      this.updateMigrateSetup_TaskSteps(lastQCTask);
      const isQCReject = true;
      const qcIntro = this.rejectInfo.QC_Reject_intro;
      const qcBody = this.rejectInfo.QC_Reject_body;
      this.updateMigrateSetup_APTaskSteps(
        lastQCTask,
        isQCReject,
        qcIntro,
        qcBody
      );

    }
  }
  }

  // get sst last task when AP QC reject and terminate 
  private getMigrateSetup_SSTTaskforQCreject(): HistoryTask {
    const sstTasks = this.migrateSetupTaskHistory
      .filter((item: HistoryTask) => SetupProcess.SST_review === item.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    const lastSSTTask = sstTasks[sstTasks.length - 2];
    return lastSSTTask;
  }

  // get sst last task
  private getMigrateSetup_SSTTask(): HistoryTask {
    const sstTasks = this.migrateSetupTaskHistory
      .filter((item: HistoryTask) => MigrateProcess.SST_review === item.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    const lastSSTTask = sstTasks[sstTasks.length - 1];
    return lastSSTTask;
  }

  // get qc last task
  private getMigrateSetup_QCTask(): HistoryTask {
    const qcTasks = this.migrateSetupTaskHistory
      .filter((item: HistoryTask) => MigrateProcess.QC_review === item.name)
      .sort(
        (first, second) =>
          moment(first.endTime).unix() - moment(second.endTime).unix()
      );
    const lastQCTask = qcTasks[qcTasks.length - 1];
    return lastQCTask;
  }

  // update complete steps
  private updateMigrateSetup_CompletedSteps(process: HistoryProcessInstance) {
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.onboarded,
    };
    const step = this.migrateSetupSteps[SupplierStatus.onboarded];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

   // update complete steps
   private updateMigrationSupplierSetup_TerminatedSteps(process: HistoryProcessInstance) {
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.terminated,
    };
    const step = this.migrateSetupSteps[SupplierStatus.terminated];
    if (step) {
      step.done = true;
      step.show = true;
      step.step = tmpItem;
    }
    // requestor form terminate: not show requestor draft step
    if (
      this.migrateSetupTaskHistory.length === 1 &&
      this.migrateSetupTaskHistory[0].variablesObj.isTerminate === true
    ) {
      const requestorDraftStep =
        this.migrateSetupSteps[SetupProcess.requestor_draft];
      requestorDraftStep.show = false;
    }
    this.migrateSetupTaskHistory.forEach((singleTask)=>{
      this.updateMigrationSupplierSetupStepsHide(singleTask);
    });
  }

  async updateMigrationSupplierSetupStepsHide(singleTask: any) {
    if (singleTask.name === AllTaskKey.supplier_accepted && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.migrateSetupSteps[SetupProcess.supplier_accepted].show = false;
    } else if (singleTask.name === AllTaskKey.profile_draft && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.migrateSetupSteps[SetupProcess.profile_draft].show = false;
    } else if (singleTask.name === AllTaskKey.SST_review) {
      if (singleTask.variablesObj && singleTask.candidateGroup == 'AP_SST'
        && !singleTask.variablesObj.SST_Approve
        && (!!singleTask.variablesObj.SST_RejectTo && (singleTask.variablesObj.SST_RejectTo !== "buyer" || singleTask.variablesObj.SST_RejectTo !== "supplier"))
        && singleTask.variablesObj.isSupplierTerminated
      ) {
        this.migrateSetupSteps[SetupProcess.SST_review].show = false;
      }
    } else if (singleTask.name === AllTaskKey.QC_review) {
      if (singleTask.variablesObj
        && !singleTask.variablesObj.QC_Approve
        && !singleTask.variablesObj.QC_Reject
        && singleTask.variablesObj.isSupplierTerminated
      ) {
        this.migrateSetupSteps[SetupProcess.QC_review].show = false;
      }
    } else if (singleTask.name === AllTaskKey.requestor_draft) {
      if (singleTask.variablesObj && singleTask.variablesObj.isSupplierTerminated && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
        this.migrateSetupSteps[SetupProcess.SST_review].show = false;
      }
    } else if (singleTask.name === AllTaskKey.GDPR && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.migrateSetupSteps[SetupProcess.GDPR].show = false;
      this.migrateSetupSteps[RiskAssessmentStatus.IS].show = false
    } else if (singleTask.name === AllTaskKey.BI && !singleTask.variablesObj.CreatedDt && !singleTask.variablesObj.UserRole) {
      this.migrateSetupSteps[SetupProcess.BI].show = false;
    }
  }

  // update ap task steps
  private updateMigrateSetup_APTaskSteps(
    task: HistoryTask,
    isReject: boolean,
    intro: string,
    body: string
  ) {
    const step = this.migrateSetupSteps[task.name];
    if (step) {
      step.reject = isReject;
      step.intro = intro;
      step.body = body;
    }
  }

  // load process histories and update steps
  private async loadProcessHistories() {
    const data = await this.workflowService.getProcessHistoriesByBusinessKey(
      this.onboardingService.supplierModel.Mapping.supplierCode
    );
    if (data.isSuccess) {
      this.processHistories = data.data;
    }
  }

  clear() {
    Object.values(this.migrateSetupSteps).map((item) => {
      item.done = false;
      item.reject = false;
    });
  }

  private async getMigrateDate(supplierCode: string): Promise<string> {
    let migrateDate: string;
    let suppliers: any;
    if (this.isNewVersion) {
      suppliers = await this.supplierService.querySupplierHistoriesEnhanced(supplierCode);
    } else {
      suppliers = await this.supplierService.querySupplierHistories(supplierCode);
    }
    if (suppliers.isSuccess) {
      if (suppliers.data) {
        migrateDate = suppliers.data[0].createdOn;
      }
    }
    return migrateDate;
  }

  private updateMigrateSetup_TerminatedSteps(createdDt: string) {
    const tmpItem: any = {
      UpdateAt: createdDt,
      status: SupplierStatus.terminated,
    };
    const step = this.migrateSetupSteps[SupplierStatus.terminated];
    if (step) {
      step.done = true;
      step.show = true;
      step.step = tmpItem;
    }
  }
}
