import { Injectable} from "@angular/core";
import { CustomHook } from "src/app/dynamic-components/interfaces/Decorators";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { DictionaryService } from "src/app/services/dictionary.service";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { ProcessNames, SetupProcess } from "src/app/interfaces/workflow";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierStatus } from "src/app/interfaces/mapping";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import {  RoleName } from "src/app/interfaces/buyer";
import { DataService } from "src/app/services/dataService";
import { Subscription } from "rxjs";
import {isOrganizationEmpty} from "../../utils/organizationCheck";
export function getDefaultValue(value: any, dataType: string) {
  if (dataType) {
    return getValue(dataType);
  }
  if (typeof value === "string") {
    return "";
  } else if (value instanceof Array) {
    return [];
  } else {
    return undefined;
  }
}
function getValue(dataType: any) {
  if (dataType) {
    if (dataType === "string") {
      return "";
    }
    if (dataType === "Array") {
      return [];
    } else {
      return undefined;
    }
  }
}
@Injectable()
export class CustomHooks {
  message:string;
  subscription: Subscription;
  constructor(
    private dictionaryService: DictionaryService,
    private onboardingService: OnBoardingService,
    private authService: AuthService,
    private languageService: LanguageService,
    private service: DynamicComponentService,
    private dataService: DataService,
  ) {}

  public requiredCountryMap = {
    CN: false,
    IE: true,
  };
  @CustomHook("ResetTimeLimit")
  ResetTimeLimit(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (context.getValue("parentModel")) {
        let timeLimit = "";
        if (context.getValue("isMET")) {
          timeLimit = `30 days after an invoice is received(PY30)`;
        } else if (context.getValue("isAsen")) {
          context
            .getValue("parentModel")
            .RequestForm.RequestorDetailsInfo.SupplierCountry.forEach(
              (d: string) => {
                if (d === "ID") {
                  timeLimit += `Indonesia - 30 days after an invoice is received (PY30)                `;
                } else if (d === "MY") {
                  timeLimit += `Malaysia - 30 days after an invoice is received (PY30)                 `;
                } else if (d === "SG") {
                  timeLimit += `Singapore (standard) - 45 days after an invoice is received (PY45)<br>Singapore (Avanade) - 30 days after an invoice is received (PY30)          `;
                } else if (d === "TH") {
                  timeLimit += `Thailand - 30 days after an invoice is received (PY30)                  `;
                }
              }
            );
        } else if (context.getValue("isANZ")) {
          context
            .getValue("parentModel")
            .RequestForm.RequestorDetailsInfo.SupplierCountry.forEach(
              (d: string) => {
                if (d === "AU") {
                  timeLimit += `Australia - 45 days after an invoice is received (PY45)                  `;
                } else if (d === "NZ") {
                  timeLimit += `New Zealand - 30 days after an invoice is received (PY30)                `;
                }
              }
            );
        } else if (context.getValue("isPH")) {
          timeLimit = `60 days after an invoice is received (PY60)`;
        } else {
          // timeLimit = `ZN60(60 Days)`
          timeLimit = `60 days after an invoice is received`;
        }
        formGroup.get("TimeLimit").setValue(timeLimit);
      }
    };
  }

  @CustomHook("dropDownOptionPrepopulate")
  dropDownOptionPrepopulate(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<void> => {
      const originalModel = context.getValue("parentModel");
      let businessRegistrationType;
      if (isOrganizationEmpty(originalModel.SupplierProfile.SupplierOrganization)) {
        this.subscription = this.dataService.currentMessage.subscribe(message => this.message = message);
        const mappingObj: any = this.message;
        if (mappingObj && mappingObj.length > 0) {
          businessRegistrationType = mappingObj.filter((item) => item.b4ptaxmaster_businessregistrationtype !== '');
        }
        if (mappingObj && mappingObj.length > 0 && businessRegistrationType !== '' && businessRegistrationType.length === 1) {
          formGroup.get(config.name).setValue(businessRegistrationType[0].b4ptaxmaster_businessregistrationtype);
        }
      } else {
        this.subscription = this.dataService.currentMessage.subscribe(message => this.message = message);
        let supOrg: any = this.message;
          setTimeout(() => {
            formGroup.get(config.name).setValue(supOrg.BusinessRegistrationType);
          },2000);
      }
    }
    

    // return async (params: any): Promise<void> => {
    //   this.subscription = this.dataService.currentMessage.subscribe(message => this.message = message);  

    //   let originalModel:any;
    //   let mappingObj:any;
    //   if(Array.isArray(this.message)){
    //     mappingObj = this.message
    //   }else{
    //     originalModel = this.message
    //   }
    //   let businessRegistrationType;
    //   if(mappingObj && mappingObj.length > 0){
    //     businessRegistrationType = mappingObj.filter((item) => item.b4ptaxmaster_businessregistrationtype !== '');
    //   }
    //   //the length check for requestorBusinessRegistrationType is due to only one tax id against a dropdown is expected from SPH
    //   if(mappingObj && mappingObj.length > 0 && businessRegistrationType !== '' && businessRegistrationType.length === 1){
    //     const requestorBusinessRegistrationType = businessRegistrationType[0].b4ptaxmaster_businessregistrationtype;
    //     // const dictionaryData = await this.dictionaryService.loadAllDictionary();
    //     const supplierOrganization = originalModel.SupplierProfile.SupplierOrganization

    //     if (Object.keys(supplierOrganization).length === 0) {
    //       // this.dataService.currentMessage.subscribe(message => this.message == message);
    //       // this.dataService.changeMessage(requestorBusinessRegistrationType);
    //           // this._ngZone.runOutsideAngular(() => {
    //           console.log('dropdown -1')
    //           //  setTimeout(() => {
    //             formGroup.get(config.name).setValue(requestorBusinessRegistrationType);
    //             // this.cdr.detectChanges();
    //           //  }, 500);
    //           // });
    //      } else {
    //       // this.dataService.currentMessage.subscribe(message => this.message == message);
    //       // this.dataService.changeMessage(supplierOrganization.BusinessRegistrationType);
    //       // this._ngZone.runOutsideAngular(() => {
    //       console.log('dropdown -2')
    //       //  setTimeout(() => {
    //         formGroup.get(config.name).setValue(supplierOrganization.BusinessRegistrationType);
    //         // this.cdr.detectChanges();
    //     //    },500);
    //     //  });
    //      }
  
    //     // for (let i = 0; i < dictionaryData.length; i++) {
    //     //   let supplierStep = dictionaryData[i].Code
    //     //   if (supplierStep === 'supplier-step2') {
    //     //     let registrationType = dictionaryData[i].Items[1].Code;
    //     //     let registrationTypedata = dictionaryData[i].Items[1].Items
    //     //     if (registrationType === 'RegistrationType') {
    //     //       registrationTypedata.forEach(element => {
    //     //         if (element.Code === this.onboardingService.supplierModel.Mapping.setUpCountry[0]) {
    //     //           element.Items.forEach(item => {
    //     //             if (config.name === 'BusinessRegistrationType') {
    //     //               if (item.Code === requestorBusinessRegistrationType && Object.keys(supplierOrganization).length === 0) {
    //     //                 // setTimeout(() => {
    //     //                   formGroup.get(config.name).setValue(item.Code);
    //     //                 // }, 500);
    //     //                }
    //     //                else if(item.Code === requestorBusinessRegistrationType && Object.keys(supplierOrganization).length > 0){
    //     //                 // setTimeout(() => {
    //     //                   formGroup.get(config.name).setValue(supplierOrganization.BusinessRegistrationType);
    //     //                 // }, 500);
    //     //                }
    //     //             }
    //     //           })
    //     //         }
    //     //       })
    //     //     }
    //     //   }
    //     // }
    //   }
    // }
  }

  @CustomHook("commonHooksForTaxValuePrepopulate")
  commonHooksForTaxValuePrepopulate(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return async (params: any): Promise<void> => {
      this.subscription = this.dataService.currentMessage.subscribe(message => this.message = message); 
      let value: any;
      const originalModel = context.getValue("parentModel");
      if (isOrganizationEmpty(originalModel.SupplierProfile.SupplierOrganization)) {
        const mappingObj: any = this.message;
        let taxValuesFromTsm, taxFieldName, filedName, responseOrganizationFieldValue;
        if (mappingObj && mappingObj.length > 0) {
          taxValuesFromTsm = mappingObj.filter((item) => item.b4ptaxmaster_taxcategory !== '');
        }
        if (taxValuesFromTsm && taxValuesFromTsm.length > 0) {
          taxFieldName = taxValuesFromTsm.filter((item) => item.b4ptaxmaster_taxcategory === config.name);
        }
        //logic to populate Business Registration Number Field
        if (config.name === 'BusinessRegistrationNb' && taxValuesFromTsm && taxValuesFromTsm.length > 0) {
          const businessRegistrationField = taxValuesFromTsm.filter((item) => item.b4ptaxmaster_taxcategory === 'Business Registration');
          if (businessRegistrationField && businessRegistrationField.length > 0) {
            filedName = config.name;
            responseOrganizationFieldValue = businessRegistrationField[0].tsmtaxmapping_tax_registration_number;
          }
        } else { //logic to set value for other tax fields apart from businessregistrationNb
          if (taxFieldName && taxFieldName.length > 0) {
            filedName = taxFieldName[0].b4ptaxmaster_taxcategory;
            responseOrganizationFieldValue = taxFieldName[0].tsmtaxmapping_tax_registration_number;
          }
        }
        if (config.name === filedName && responseOrganizationFieldValue !== '') {
          value = responseOrganizationFieldValue;
          setTimeout(() => {
            formGroup.get(config.name).setValue(value);
          },2500);
        }
      } else {
        value = this.message[config.name];
        if(value && value !== ''){
          setTimeout(() => {
            formGroup.get(config.name).setValue(value);
          },2500);
        }
      }
    };


    // return async (params: any): Promise<void> => {   
    //   this.subscription = this.dataService.currentMessage.subscribe(message => this.message = message);   
    //   const originalModel = context.getValue("parentModel");
    //   const testObj = this.onboardingService.supplierModel.Mapping;
    //   const mappingObj:any = this.message;
    //   let taxValuesFromTsm, taxFieldName, filedName, responseOrganizationFieldValue;
    //   if(mappingObj && mappingObj.length > 0){
    //     taxValuesFromTsm = mappingObj.filter((item) => item.b4ptaxmaster_taxcategory !== '');
    //   }
    //   if(taxValuesFromTsm && taxValuesFromTsm.length > 0){
    //     taxFieldName = taxValuesFromTsm.filter((item) => item.b4ptaxmaster_taxcategory === config.name);
    //   }
    //   // const requestorObj = this.onboardingService.supplierModel.Mapping.requestor
    //   // const responseOrganizationFieldName = requestorObj.tsmTaxCategoryFieldName;
    //   const supplierOrganization = originalModel.SupplierProfile.SupplierOrganization;
    //   // const supplierObj = originalModel.SupplierProfile.SupplierOrganization;
    //   //logic to set value for BusinessRegistrationNb field
    //   if(config.name === 'BusinessRegistrationNb' && taxValuesFromTsm && taxValuesFromTsm.length > 0) {
    //     const businessRegistrationField = taxValuesFromTsm.filter((item) => item.b4ptaxmaster_taxcategory === 'Business Registration');
    //     if(businessRegistrationField && businessRegistrationField.length > 0){
    //       filedName = config.name;
    //       responseOrganizationFieldValue = businessRegistrationField[0].tsmtaxmapping_tax_registration_number;
    //     }
        
    //   } else {
    //     //logic to set value for other tax fields apart from businessregistrationNb
    //     if(taxFieldName && taxFieldName.length > 0){
    //       filedName = taxFieldName[0].b4ptaxmaster_taxcategory;
    //       responseOrganizationFieldValue = taxFieldName[0].tsmtaxmapping_tax_registration_number;
    //     }
    //   }
    //   if (config.name === filedName && responseOrganizationFieldValue !== '') {        
    //     const isKeyExist: boolean = supplierOrganization[filedName] ? true : false;       
    //     let value: any;
    //     if (!isKeyExist) {
    //       value = responseOrganizationFieldValue;
    //     } else if (isKeyExist && responseOrganizationFieldValue !== supplierOrganization[filedName]) {
    //       value = supplierOrganization[filedName]
    //     } else {
    //       value = responseOrganizationFieldValue
    //     }
    //     // this.dataService.currentMessage.subscribe(message => this.message == message);
    //     // this.dataService.changeMessage(value);
    //     // this._ngZone.runOutsideAngular(() => {
    //     console.log('taxfor other -1');
    //     //  setTimeout(() => {
    //     formGroup.get(config.name).setValue(value);
    //     // this.cdr.detectChanges();
    //   //    },600);
    //   // });
    //     return
    //   }

    //   if(Object.keys(supplierOrganization).length > 1 && responseOrganizationFieldValue !== supplierOrganization[filedName]){        
    //     let editedFieldValue;
    //     editedFieldValue = supplierOrganization[config.name];
    //     this._ngZone.runOutsideAngular(() => {
    //     console.log('taxfor other -2');
    //     setTimeout(() => {
    //     formGroup.get(config.name).setValue(editedFieldValue);
    //     },600);
    //   });
    //   }
    // };
  }

  @CustomHook("POCEmailToEnableInput")
  POCEmailToEnableInput(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if(context.isEditMode() && context.getValue("parentModel").Mapping.status ==
      SupplierStatus.onboarded) {
        config.type='input'
      }
      else { 
        config.type='textbox'
      }
      if(config.name === 'PrimaryContactEmail'){
        formGroup.get(config.name).setValue(context.getValue("parentModel").Mapping.supplierEmail);
      }      
    }
  }

  @CustomHook("ResetPaymentTermsField")
  ResetPaymentTermsField(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const supplierOrganization =
        context.parent.getValue("parentModel").SupplierProfile
          .SupplierOrganization;
      if (context.getModel().TermPayment) {
        let dictionnary = [];
        if (context.parent.getValue("isNA")) {
          dictionnary = this.dictionaryService.getDictionaryFromCache(
            "requestor-form.SpendCommodityGroupNA"
          );
        } else if (context.parent.getValue("isAsen")) {
          dictionnary = this.dictionaryService.getDictionaryFromCache(
            "requestor-form.SpendCommodityGroupAsen"
          );
        } else if (context.parent.getValue("isANZ")) {
          dictionnary = this.dictionaryService.getDictionaryFromCache(
            "requestor-form.SpendCommodityGroupANZ"
          );
        } else if (context.parent.getValue("isGreaterChina")) {
          dictionnary = this.dictionaryService.getDictionaryFromCache(
            "requestor-form.SpendCommodityGroupGreaterChina"
          );
        } else {
          dictionnary = this.dictionaryService.getDictionaryFromCache(
            "requestor-form.SpendCommodityGroup"
          );
        }
        const paymentTermsField = this.languageService.getValue(
          dictionnary.find((p) => p.Code == context.getModel().TermPayment)
            .languageKey
        );
        formGroup.get("PaymentTermsField").setValue(paymentTermsField);
      } else {
        let paymentTermsForField =
          !!context.getModel().TimeLimit && !!context.getModel().LanguageKey
            ? this.languageService.getValue(context.getModel().LanguageKey)
            : context.getModel().TimeLimit;
        if (!paymentTermsForField) {
          paymentTermsForField = context.getModel().TimeLimit;
        }
        if (
          supplierOrganization.EnterpriseType &&
          (supplierOrganization.EnterpriseType === "01" ||
            supplierOrganization.EnterpriseType === "02")
        ) {
          paymentTermsForField = this.languageService.getValue(
            "Hook.ResetPaymentTermsField.PaymentTermList.EnterpriseType_01_02"
          );
        } else if (
          context.parent.getValue("isIN") &&         
           context.parent.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('LK')
        )         {
          paymentTermsForField = this.languageService.getValue(
            "Hook.ResetPaymentTermsField.PaymentTermList.EnterpriseType_04"
          );
        }   
         else if (
          context.parent.getValue("isIN") &&
          (!supplierOrganization.EnterpriseType ||
            supplierOrganization.EnterpriseType === "03")
        ) {
          paymentTermsForField = this.languageService.getValue(
            "Hook.ResetPaymentTermsField.PaymentTermList.EnterpriseType_03"
          );
        }
        // switch (context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical) {
        //   case '26': paymentTermsForField = 'PY60 – 60 days after an invoice is received'; break;
        //   case '24': paymentTermsForField = 'MET – 30 days after an invoice is received (PY 30)'; break
        //   case '12':
        //     {
        //       let supplierCountry = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
        //       if (supplierCountry.indexOf('ID') !== -1) {
        //         paymentTermsForField = 'Indonesia - 30 days after an invoice is received (PY30);';
        //       }
        //       if (supplierCountry.indexOf('MY') !== -1) {
        //         paymentTermsForField += 'Malaysia - 30 days after an invoice is received (PY30);';
        //       }
        //       if (supplierCountry.indexOf('SG') !== -1) {
        //         paymentTermsForField += 'Singapore – 45 days after an invoice is received(PY45),30 days after an invoice is received(PY30) for Avanade;';
        //       }
        //       if (supplierCountry.indexOf('TH') !== -1) {
        //         paymentTermsForField += 'Thailand - 30 days after an invoice is received (PY30)';
        //       }
        //     }
        //     break;
        //   case '11': {
        //     let supplierCountry = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
        //     if (supplierCountry.indexOf('AU') != -1) {
        //       paymentTermsForField = 'Australia - 45 days after an invoice is received (PY 45);';
        //     }
        //     if (supplierCountry.indexOf('NZ') != -1) {
        //       paymentTermsForField += 'ANZ – 30 days after an invoice is received (PY 30)';
        //     }
        //   }
        //     break
        //   case '28': paymentTermsForField = 'PY60(60 Days)'; break;

        // }
        formGroup.get("PaymentTermsField").setValue(paymentTermsForField);
      }
    };
  }

  @CustomHook("BooleanFormat")
  BooleanFormat(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (formGroup.controls[config.name]) {
        const currentValue = formGroup.controls[config.name].value;
        if (currentValue === true) {
          formGroup.get(config.name).setValue("yes");
        }
      }
    };
  }

  @CustomHook("ApRejectPOCEmailDisable")
  ApRejectPOCEmailDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const originalModel = context.getValue("parentModel");
      if (
        context.isEditMode() &&
        originalModel.Mapping.historyTasks.length > 0
      ) {
        formGroup.get(config.name).clearValidators();
        formGroup.get(config.name).clearAsyncValidators();
        formGroup
          .get(config.name)
          .disable({ onlySelf: true, emitEvent: false });
      }
    };
  }

  @CustomHook("ApRejectSupplierNameDisable")
  ApRejectDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const originalModel = context.getValue("parentModel");
      if (
        context.isEditMode() &&
        originalModel.Mapping.historyTasks.length > 2
      ) {
        formGroup.get(config.name).clearValidators();
        formGroup.get(config.name).clearAsyncValidators();
        formGroup
          .get(config.name)
          .disable({ onlySelf: true, emitEvent: false });
      }
    };
  }

  @CustomHook("businessRegistrationNbWhenUSOrPR")
  businessRegistrationNbWhenUSOrPR(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countries: Array<string> =
        context.get("parentModel").value.RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      if (countries.includes("US") || countries.includes("PR")) {
        config.label = this.languageService.getValue(
          "Hook.businessRegistrationNbWhenUSOrPR.BusinessRegistrationNb.US_PR_label"
        );
        config.templateOptions.IsVerticalShowSublable = true;
        config.width = "";
        config.templateOptions.hideWidth = "";
        context["resetBusinessRegistrationNbLabel"] = config.label;
      }
    };
  }

  @CustomHook("businessRegistrationNbTooltip")
  businessRegistrationNbTooltip(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (context.getValue("isANZ") || context.getValue("isGreaterChina")) {
        const currentSupplierRegisteredCountry =
          context.getValue("parentModel").SupplierProfile.SupplierOrganization
            .SupplierRegisteredCountry;

        // refer to PBI 1437570
        if (
          currentSupplierRegisteredCountry == "DK" ||
          currentSupplierRegisteredCountry == "FI" ||
          currentSupplierRegisteredCountry == "LV" ||
          currentSupplierRegisteredCountry == "EE" ||
          currentSupplierRegisteredCountry == "NO" ||
          currentSupplierRegisteredCountry == "SE"
        ) {
          config.tooltip = this.languageService.getValue(
            "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isANZ_currentSupplierRegisteredCountry_DK_FI_LV_tooltip"
          );
        }
        // refer to PBI 550995
        else {
          config.tooltip = this.languageService.getValue(
            "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isANZ_currentSupplierRegisteredCountry_Else_tooltip"
          );
        }
        return;
      }

      if (context.getValue("isPH")) {
        const currentSupplierRegisteredCountry =
          context.getValue("parentModel").SupplierProfile.SupplierOrganization
            .SupplierRegisteredCountry;

        // refer to PBI 1437570
        if (
          currentSupplierRegisteredCountry == "DK" ||
          currentSupplierRegisteredCountry == "FI" ||
          currentSupplierRegisteredCountry == "LV" ||
          currentSupplierRegisteredCountry == "EE" ||
          currentSupplierRegisteredCountry == "NO" ||
          currentSupplierRegisteredCountry == "SE"
        ) {
          config.tooltip = this.languageService.getValue(
            "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isPH_currentSupplierRegisteredCountry_DK_FI_LV_tooltip"
          );
        }
        // refer to PBI 570651
        else {
          config.tooltip = this.languageService.getValue(
            "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isPH_currentSupplierRegisteredCountry_Else_tooltip"
          );
        }
        return;
      }

      if (context.getValue("isSG")) {
        const currentSupplierRegisteredCountry =
          context.getValue("parentModel").SupplierProfile.SupplierOrganization
            .SupplierRegisteredCountry;

        // refer to PBI 1437570
        if (
          currentSupplierRegisteredCountry == "DK" ||
          currentSupplierRegisteredCountry == "FI" ||
          currentSupplierRegisteredCountry == "LV" ||
          currentSupplierRegisteredCountry == "EE" ||
          currentSupplierRegisteredCountry == "NO" ||
          currentSupplierRegisteredCountry == "SE"
        ) {
          config.tooltip = this.languageService.getValue(
            "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isSG_currentSupplierRegisteredCountry_DK_FI_LV_tooltip"
          );
        }
        // refer to PBI 508361
        else {
          config.tooltip = this.languageService.getValue(
            "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isSG_currentSupplierRegisteredCountry_Else_tooltip"
          );
        }
        return;
      }
    };
  }

  @CustomHook("RequestorResubmitDisable")
  RequestorResubmitDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (
        context.isEditMode() &&
        this.onboardingService.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.requestor_draft
        ) &&
        !this.onboardingService.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.supplier_accepted
        )
      ) {
        formGroup.get(config.name).clearValidators();
        formGroup.get(config.name).clearAsyncValidators();
        formGroup
          .get(config.name)
          .disable({ onlySelf: true, emitEvent: false });
      }
    };
  }

  @CustomHook("DefaultDisable")
  DefaultDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      formGroup.get(config.name).clearValidators();
      formGroup.get(config.name).clearAsyncValidators();
      formGroup.get(config.name).disable({ onlySelf: true, emitEvent: false });
    };
  }
  @CustomHook("SpendCategoryGroupDisable")
  SpendCategoryGroupDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      formGroup.get(config.name).clearValidators();
      formGroup.get(config.name).clearAsyncValidators();
      formGroup.get(config.name).disable({ onlySelf: true, emitEvent: false });
      let roleCode: string = this.authService.passport.buyer.RoleCode;
      if( roleCode==RoleName.SourcingCategoryManagement){
        formGroup.get(config.name).enable({ onlySelf: true, emitEvent: false });
      }
      if(context.getValue("isSSTReject") && context.isEditMode()){
        formGroup.get(config.name).clearValidators();
        formGroup.get(config.name).clearAsyncValidators();
        formGroup
          .get(config.name)
          .disable({ onlySelf: true, emitEvent: false });
        //disabled as this US 432840 is not a part R13.1.0 release and follow up US is not yet implemented
      }
    };
  }
  
  @CustomHook("SpendCommodityGroupDisable")
  SpendCommodityGroupDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      formGroup.get(config.name).clearValidators();
      formGroup.get(config.name).clearAsyncValidators();
      formGroup.get(config.name).disable({ onlySelf: true, emitEvent: false });
      let roleCode: string = this.authService.passport.buyer.RoleCode;
      if(roleCode==RoleName.SourcingCategoryManagement){
        formGroup.get(config.name).enable({ onlySelf: true, emitEvent: false });
      }
      if(context.getValue("isSSTReject") && context.isEditMode()){
        formGroup.get(config.name).clearValidators();
        formGroup.get(config.name).clearAsyncValidators();
        formGroup
          .get(config.name)
          .disable({ onlySelf: true, emitEvent: false });
        //disabled as this US 432840 is not a part R13.1.0 release and follow up US is not yet implemented
      }
    };
  }

  @CustomHook("SyncCountry")
  SyncCountry(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const originalModel = context.getValue("parentModel");
      if (!originalModel.SupplierProfile.SupplierOrganization.SupplierRegisteredCountry) {
        formGroup.get(config.name).setValue(originalModel.SupplierProfile.SupplierGeneral.Country);
      }
    };
  }

  @CustomHook("showLabelBySupplierCompanyClassify")
  showLabelBySupplierCompanyClassify(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countrie: string = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
      const gu: string = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical
      if (gu === '19' || gu === '25' || gu === '13' || gu === '20' || gu === '27' || gu === '28' || gu === '21' || gu === '24'){
        if(countrie.includes('TR')){
          config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isTurkey_Label")
        }
        else if(!countrie.includes('IT')){
          config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isIberia_Label")
        }
      }else if (gu === '16' && (countrie.includes('MU')||countrie.includes('MA')||countrie.includes('VN'))) {
        config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isGallia_MU_Label")
      }else if (gu === '16' && (!countrie.includes('MU')||!countrie.includes('MA')||!countrie.includes('VN')) || gu ==='22') {
        config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isGallia_NonMU_Label")
      }
      /*       else if (countrie.includes('AU')){
        config.label = 'Is your company a small Australian business with annual turnover for the most recent income year of less than $10m?'
      }else if (countrie.includes('NZ')){
        config.label = 'Is your company a small New Zealand business that employs fewer than 20 employees? '
      } */
    };
  }

  @CustomHook("dynamicLink")
  dynamicLink(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const Tsm_link: string = context.getValue("TsmLink");
      const update_according_message = this.languageService.getValue(
        "Hook.dynamicLink.Country.update_according_message"
      );
      const here = this.languageService.getValue(
        "Hook.dynamicLink.Country.here"
      );
      if (context.getValue("isComplete")) {
        const env = sessionStorage.getItem("tsmEnv");
        const url =
          env === "dev"
            ? environment.landingdev_url
            : env === "perf"
            ? environment.landingperf_url
            : env === "hotfix"
            ? environment.landinghotfix_url
            : env === "stage"
            ? environment.landingstage_url
            : env === "test"
            ? environment.landingtest_url
            : environment.landing_url;

        const loginHint =
          this.authService.passport.supplier.Mapping.supplierEmail;

        const urlHint = url + "?login_hint=" + loginHint;
        // window.location.href= url
        config.tooltip =
          `<li>${update_according_message}<a style="color: #006caf" target="_blank" href="` +
          urlHint +
          `">${here}</a></li>`;
      } else {
        config.tooltip =
          `<li>${update_according_message}<a style="color: #006caf" target="_blank" href="` +
          Tsm_link +
          `">${here}</a></li>`;
      }
    };
  }

  @CustomHook("dynamicLinkForTSMBuyer")
  dynamicLinkForTSMBuyer(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const Tsm_link: string = context.getValue("TsmLink");
      config.tooltip =
        '<li>if you would like to request this supplier for onboarding in a country other than the country mentioned here, please click <a style="color: #006caf" target="_blank" href="' +
        Tsm_link +
        '">here</a>.(You will get the opportunity to search for this supplier in the according country and if non-existent add the supplier to the system)</li>';
    };
  }

  @CustomHook("dynamicLinkForTSMBuyerSupplierGeographical")
  dynamicLinkForTSMBuyerSupplierGeographical(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      if (config.type === "textboxview") {
        return;
      }
      const Tsm_link: string = context.getValue("TsmLink");
      if(config.type === "dropdownTextboxview"){
        config.type = "textboxview"
      }
      if (context.getValue("isInit")) {
        const env = sessionStorage.getItem("tsmEnv");
        const url =
          env === "dev"
            ? environment.landingdev_url
            : env === "perf"
            ? environment.landingperf_url
            : env === "hotfix"
            ? environment.landinghotfix_url
            : env === "stage"
            ? environment.landingstage_url
            : env === "test"
            ? environment.landingtest_url
            : environment.landing_url;
        // window.location.href= url
        //config.tooltip='<li>To set up a supplier providing goods & services to the UKIA, please use the</li><li><a style="color: #006caf" target="_blank" href="'+ url + '">True Supplier Marketplace</a></li>'
       config.subLabel='<br>To set up a supplier providing goods & services to the UKIA, ANZ, Iberia, Nordics, ASG, ICEG, Gallia, North America, Philippines, SEA, Greater China, India, Africa, Middle East and Japan please use the <a style="color: #006caf" target="_blank" href="'+ url + '?login_hint=' + this.authService.passport.buyer.Email + '">True Supplier Marketplace</a>' 
      //  config.type='input'
      }else {
       config.subLabel='<br>To set up a supplier providing goods & services to the UKIA, ANZ, Iberia, Nordics, ASG, ICEG, Gallia, North America, Philippines, SEA, Greater China, India, Africa, Middle East and Japan please use the <a style="color: #006caf" target="_blank" href="'+ Tsm_link + '">True Supplier Marketplace</a>'

      }
    };
  }
  @CustomHook("CompanyRegisteredUKIALabel")
  companyRegisteredUKIALabel(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countryServed: string = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
      const GU: string = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierGeographical
      const TsmId: string = this.onboardingService.supplierModel.Mapping.TsmId;
      if (!!TsmId){
        if (countryServed.includes('IE')) {
          config.label = 'Does your company classify as  an independent micro, small, or medium-sized enterprise (SME) in Ireland?'
        } else if (countryServed.includes('GB')){
          config.label = 'Does your company classify as an independent micro, small, or medium-sized enterprise (SME) in the UK?'
        }  else if (GU === '19' || GU === '25'  || GU === '13' || GU === '20' || GU === '27' || GU === '28' || GU === '21' || GU === '10' || GU === '24') {
          if(countryServed.includes('IT')){
            config.hide = true;
            config.width = "100%";
          } else if(countryServed.includes('TR')){
            config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isTurkey_Label")
          }
          else {
            config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isIberia_Label")
          }
        } else if (countryServed.includes('MU')||countryServed.includes('MA')||countryServed.includes('VN')) {
          config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isGallia_MU_Label")
        } else if (GU === '16') {
          config.label = this.languageService.getValue("Hook.changeConfig.CompanyRegistered.isGallia_NonMU_Label")
        }else {
          config.hide = true;
          config.width = "100%";
        } 
      } 
      else {
        config.hide = true;
        config.width = "100%";
      }  
      /*       else if (countryServed.includes('AU')){
        config.label = 'Is your company a small Australian business with annual turnover for the most recent income year of less than $10m?'
      }else if (countryServed.includes('NZ')){
        config.label = 'Is your company a small New Zealand business that employs fewer than 20 employees? '
      } */
    };
  }

  @CustomHook("CompanyTypeTips")
  CompanyTypeTips(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countryServed: string =
        context.getValue("parentModel").RequestForm.RequestorDetailsInfo
          .SupplierCountry;
      if (countryServed.includes("IE")) {
        config.label =
          '<i class="material-icons waringMsg">warning</i>' +
          "<br>" +
          "Micro company - The following requirements are fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 10<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 2 million OR Balance sheet total is not more than € 2 million<br>" +
          "Small company - The following requirements are fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 50<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 10 million OR Balance sheet total is not more than € 10 million<br>" +
          "Medium-sized company - The following requirements are fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 250<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 50 million OR Balance sheet total is not more than € 43 million<br>" +
          "Source: EU “SME Definition”: https://ec.europa.eu/growth/smes/sme-definition_en.";
        config.hide = false;
        config.required = true;
        config.label =
          "Does your company classify as an independent micro, small, or medium-sized enterprise (SME) in Ireland? Please select your company size.";
      } else if (countryServed.includes("GB")) {
        config.staticMessage =
          '<i class="material-icons waringMsg">warning</i>' +
          " <br>" +
          "Micro company - At least two out of the following three requirements need to be fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is not more than 10<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than £632,000<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance sheet total is not more than £316,000<br>" +
          "Small company - At least two out of the following three requirements need to be fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is not more than 50<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than £10.2 million<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance sheet total is not more than £5.1 million<br>" +
          "Medium-sized company - At least two out of the following three requirements need to be fulfilled: <br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is not more than 250<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than £36 million<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance sheet total is not more than £18 million<br>" +
          "Source: UK Companies Act 2006.";
        config.hide = false;
        config.required = true;
        config.label =
          "Does your company classify as an independent micro, small, or medium-sized enterprise (SME) in the UK? Please select your company size.";
      } else {
        /*       else if (countryServed.includes('AU')){
        config.staticMessage =
                
        '<i class="material-icons waringMsg">warning</i>'+' <br>'+
        'Sole Proprietorship –<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Individual/Sole proprietorship owned and run by one person<br>' +
        'Small Organisation-<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Company with less than 50 employees, and with an annual turnover not exceeding 10 million USD<br>' +
        'Medium Organisation - <br>' +        
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company with less than 250 employees, and with an annual turnover not exceeding 50 million USD<br>' +
        'Defined by the Business Council of Australia.'
        config.hide = false
        config.required = true
        config.label = 'Is your company a small Australian business with annual turnover for the most recent income year of less than $10m? Please select your Organisation size.'
      }else if (countryServed.includes('NZ')){
        config.staticMessage =
                
        '<i class="material-icons waringMsg">warning</i>'+' <br>'+
        'Sole Proprietorship –<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Individual/Sole proprietorship owned and run by one person<br>' +
        'Small Organisation-<br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Company with less than 50 employees, and with an annual turnover not exceeding 10 million USD<br>' +
        'Medium Organisation - <br>' +        
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company with less than 250 employees, and with an annual turnover not exceeding 50 million USD<br>' +
        'Defined by the Business Council of Australia.'
        config.hide = false
        config.required = true
        config.label = 'Is your company a small New Zealand business that employs fewer than 20 employees?  Please select your Organisation size.'
      } */
        config.required = false;
        config.hide = true;
      }
    };
  }

  @CustomHook("setDisable")
  setDisable(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      formGroup.get(config.name).disable({ onlySelf: true, emitEvent: false });
    };
  }

  @CustomHook("setDisableBankInfoOnProcModeOfPmt")
  setDisableBankInfoOnProcModeOfPmt(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    
    return (params: any): void => {
      config.type = "dropdown";
      formGroup.get(config.name).disable({ onlySelf: true, emitEvent: false });
    };
  }

  @CustomHook("setLatestThreeYears")
  setLatestThreeYears(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      // 1st
      if (config.name.indexOf("LatestFirstYear") != -1) {
        const firstYearHidden = formGroup.controls["FirstYearHidden"].value;
        // if new form, set value for hidden component, set label for current component
        if (!firstYearHidden || firstYearHidden == "") {
          const date = new Date();
          const year = date.getFullYear() - 1;
          formGroup.get("FirstYearHidden").setValue(year + "");
          config.label = year + " " + config.label;
        } else {
          config.label = firstYearHidden.substring(0, 4) + " " + config.label;
        }
      }
      // 2nd
      else if (config.name.indexOf("LatestSecondYear") != -1) {
        const secondYearHidden = formGroup.controls["SecondYearHidden"].value;
        // if new form, set value for hidden component, set label for current component
        if (!secondYearHidden || secondYearHidden == "") {
          const date = new Date();
          const year = date.getFullYear() - 2;
          formGroup.get("SecondYearHidden").setValue(year + "");
          config.label = year + " " + config.label;
        } else {
          config.label = secondYearHidden.substring(0, 4) + " " + config.label;
        }
      }
      //3rd
      else if (config.name.indexOf("LatestThirdYear") != -1) {
        const thirdYearHidden = formGroup.controls["ThirdYearHidden"].value;
        // if new form, set value for hidden component, set label for current component
        if (!thirdYearHidden || thirdYearHidden == "") {
          const date = new Date();
          const year = date.getFullYear() - 3;
          formGroup.get("ThirdYearHidden").setValue(year + "");
          config.label = year + " " + config.label;
        } else {
          config.label = thirdYearHidden.substring(0, 4) + " " + config.label;
        }
      }
    };
  }

  @CustomHook("hide_Iberia")
  hide_Iberia(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (): void => {
      if (context.getValue("isIberia") && formGroup.get("OnetimeOrRecurringPurchases").value!=="10") {
        config.hide = true;
      }
      else if(context.getValue("isICEG") && context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes("IT")){
        config.hide = false;
      }
      else config.hide = true;
    };
  }

  @CustomHook("companyRegisteredUKIATooltip")
  companyRegisteredUKIATooltip(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const countryServed: string = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
      if (context.getValue("isIberia")) {
        config.tooltip = this.languageService.getValue(
          "Hook.businessRegistrationNbTooltip.BusinessRegistrationNb.isIberia_currentSupplierRegisteredCountry_tooltip"
        );
        return;
      }
      if (context.getValue('isNordics')) { 
        config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        return
      }
      if (context.getValue('isASG')) {
        if(countryServed.includes('TR')){
          config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltipTR")
        } else{
          config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        }
        return
      }
      if (context.getValue('isCEG')) { 
        config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        return
      }
      if (context.getValue('isPH')) { 
        config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        return
      }
      if (context.getValue('isNA')) { 
        config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        return
      }
      if (context.getValue('isIN')) {
        //config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        config.tooltip = '<table border="1"cellspacing="0" style="font-size:10px;color:black;">' +
        '<colgroup><col style="width: 100px;"><col style="width: 400px;"></colgroup>' +
        '<tr style="background-color:#5e96de; text-align: center;">' +
        "<td>Category</td>" +
        "<td>Criteria</td>" +
        "</tr>" +
        '<tr>' + 
        "<td>Micro Enterprise</td>" + 
        "<td>Where the investment in plant and machinery or equipment does not exceed one crore rupees and turnover does not exceed five crore rupees</td>" + 
        "</tr>" +
        '<tr>' +
        "<td>Small Enterprise</td>" +
        "<td>Where the investment in plant and machinery or equipment does not exceed ten crore rupees and turnover does not exceed fifty crore rupees</td>" +
        "</tr>" +
        '<tr>' +
        "<td>Medium Enterprise</td>" +
        "<td>Where the investment in plant and machinery or equipment does not exceed fifty crore rupees and turnover does not exceed two hundred and fifty crore rupees</td>" +
        "</tr></table>";
        return
      }
      if (context.getValue('isMET')) { 
        config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        return
      }
      if (context.getValue('isUKIA')) { 
        config.tooltip = this.languageService.getValue("supplier.step4.EnterpriseScale.tooltip")
        return
      }
    }
  }

  @CustomHook("updateSublabel")
  updateSublabel(config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup) {
      return (): void => {
        config.subLabel = this.languageService.getValue("Hook.updateSublabel.IntermediaryAddFinancialAttachments.isUKIA_isICEG_isNordics_isASG_isMET_isNA_sublabel")
        return
      }
  }


  @CustomHook("hide_ssn")
  hide_ssn(
    config: ComponentConfig
  ) {
    return (): void => {
      if (this.authService.passport.profile.email === this.onboardingService.supplierModel.Mapping.requestor.createdBy) {
        config.hide = true;
      }
    };
  }
  @CustomHook("changePrimaryContactTypeForJP")
  changePrimaryContactTypeForJP(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup 
  )
  {
    return (params: any): void => { 
        formGroup
          .get(config.name)
          .get(config.fieldset[0].name)
          .patchValue("+81", { onlySelf: true, emitEvent: true });
          formGroup.get(config.name).get(config.fieldset[0].name).disable();
     
    };
  }

  @CustomHook("IndividualSupplier")
  IndividualSupplier(
  config: ComponentConfig,
  context: DynamicContext,
  formGroup: UntypedFormGroup
)
{
  return (params: any): void => {
    console.log("config", config)
      console.log("context", context)
    formGroup
      .get(config.name)
      .patchValue("Individual", { onlySelf: true, emitEvent: true });
      formGroup.get(config.name).disable();
  }
 
};

  @CustomHook("contractStatusIsDisable")
  contractStatusIsDisable(config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup) {
      return (): void => {
        if (
          ((formGroup.get("SupplierGeographical").value === "13" ||
            formGroup.get("SupplierGeographical").value === "20" ||
            formGroup.get("SupplierGeographical").value === "25") &&
            (formGroup.get("SignContract").value === "12" ||
              formGroup.get("SignContract").value === "")) ||
          formGroup.get("SupplierGeographical").value === "28"
        ) {
          config.hide = true;
        }
        const control = formGroup.get(config.name);
        if (!control) {
          return;
        }
        if (config.hide) {
          const defaultValue = getDefaultValue(control.value, config.datatype);
          control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
          control.clearValidators();
          control.clearAsyncValidators();
          control.setErrors(null);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
          control.markAsPristine();
          control.markAsUntouched();
        } else {
          if (!control.value) {
            if (config.defaultValue && !control.value) {
              control.patchValue(config.defaultValue, {
                onlySelf: true,
                emitEvent: true,
              });
            } else if (control.dirty) {
              control.patchValue("", { onlySelf: true, emitEvent: true });
            }
          }
          control.setValidators(
            this.service.validatorFactory.createValidators(config, context)
          );
          control.setAsyncValidators(
            this.service.validatorFactory.createAsyncValidators(config, context)
          );
          control.setErrors(null);
          control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        }
        return
      }
  }
  @CustomHook("showDistrictChangeLabel")
  showDistrictChangeLabel(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const onboardedCountry: Array<string> = context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry;
      const country =  context.getValue("parentModel").SupplierProfile.SupplierGeneral.Country
      if ((onboardedCountry.includes("RS") && country === "RS")||(onboardedCountry.includes("TR") && country === "TR")) {
        config.label =this.languageService.getValue("supplier.step1.District.templateOptions.label")
      }else {
        config.label =this.languageService.getValue("supplier.step1.District.label")
      }
    };
  }
  @CustomHook("showVATChangeLabel")
  showVATChangeLabel(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const onboardedCountry: Array<string> = context.getValue("parentModel").RequestForm.RequestorDetailsInfo.SupplierCountry;
      const supplierRegisteredCountry=context.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry;
      if ((onboardedCountry.includes("LK") && supplierRegisteredCountry === "LK")) {
        config.label =this.languageService.getValue("supplier.step2.BusinessRegistrationNb.templateOptions.VatLabel")
      }else {
        config.label =this.languageService.getValue("supplier.step2.BusinessRegistrationNb.label")
      }
    };
  }
  @CustomHook("setVariableLabelByTurkey")
  setVariableLabelByTurkey(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const originalFinancialInfo =
      context.getValue("parentModel").SupplierProfile.SupplierFinancial.PaymentBankingInformationList.filter(
        (p) =>p
      )[0];
      const originalThirdPartyFinancialInfo =
      context.getValue("parentModel").SupplierProfile.SupplierFinancial.ThirdPartyPaymentInformationList.filter(
        (p) =>p
      )[0];
      if(config.name=="IntermediaryBankKey"){
      if(originalFinancialInfo.IntermediaryBankCountry=== 'TR' && context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')){
        config.label =this.languageService.getValue(
          "Expression.setVariableLabel.IntermediaryBankKey_ASG_TR_label");
      }
    }
    else if(config.name=="ThirdPartyBankKey"){
      if(originalThirdPartyFinancialInfo.ThirdPartyBankCountry=== 'TR' && context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')){
        config.label =this.languageService.getValue(
          "Expression.setVariableLabel.ThirdPartyBankKey_ASG_TR_label");
      }
    }
    };
  }
  @CustomHook("showTurkeyLabel")
  showTurkeyLabel(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const originalFinancialInfo =
      context.getValue("parentModel").SupplierProfile.SupplierFinancial.PaymentBankingInformationList.filter(
        (p) =>p
      )[0];     
      if(config.name=="BankKey"){
      if(originalFinancialInfo.BankCountry=== 'TR' && context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry.includes('TR') && (context.getValue("parentModel").SupplierProfile.SupplierOrganization.SupplierRegisteredCountry == 'TR')){
        config.label =this.languageService.getValue(
          "Expression.setVariableLabel.BankKey_ASG_TR_label");
      }
    }
    else {      
        config.label =this.languageService.getValue(
          "Expression.setVariableLabel.BankKey_Default_label");
      }
    
    };
  
}

}
