import { AuthService } from "./../../../services/auth/auth.service";
import { Component, OnInit, Input, ViewChild, OnChanges } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { DictionaryService } from "src/app/services/dictionary.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicViewComponent } from "src/app/dynamic-components/components/dynamic-view/dynamic-view.component";
import { BuyerCommonService } from "src/app/modules/buyer/services/buyerCommonService";
import { LanguageService } from "../../../dynamic-components/utils/language.service";
import { LoadingService } from "src/app/services/loading.service";
import * as _ from "lodash";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.sass"],
})
export class HistoryComponent implements OnInit, OnChanges {
  @Input() supplierProfiles: any[];
  @Input() historyList: any;
  item: any;
  baseUrl = environment.gateway;
  context: DynamicContext;
  bankCountry: string;
  thirdPartyBankCountry: string;
  IntermediaryBankCountry: string;
  termPayment: string;
  country: string;
  registeredCountry: string;
  AddGSTCertificateAttachments: string;
  @ViewChild("dynamicView") dynamicView: DynamicViewComponent;
  configMap = new Map();
  loaded = false;

  constructor(
    private auth: AuthService,
    private dictionaryService: DictionaryService,
    private buyerCommonService: BuyerCommonService,
    private languageService: LanguageService,
    private loadingService: LoadingService
  ) {}
  ngOnInit() {}
  async ngOnChanges() {
    this.loaded = false;

    // this.historyListFilter = new Array<any>()

    if (!this.context) {
      this.context = new DynamicContext();
    }
    await this.buyerCommonService.SetContextValue(this.context);
    await this.buyerCommonService.SetContextSupplierProfile(this.context);
    // if(this.context.get('TsmId')){

    //   this.historyList.forEach((t,i) => {
    //     if(t.typeInfo.indexOf('Compliance') == -1){
    //       this.historyListFilter.push(t)
    //     }
    //   });
    //   // this.historyList.splice(number,1)
    // }
    // bug fixed 2008548 distinct company code and payment term list
    const distinctList = (values: any[]) => {
      const list = [];
      if (values.length === 0) {
        return list;
      }
      const temp = _.cloneDeep(values[0]);
      let newValues = _.map(values, "newValue");
      let oldValues = _.map(values, "oldValue");
      _.each(newValues, (newValue, newIndex) => {
        _.each(oldValues, (oldValue, oldIndex) => {
          if (newValue === oldValue) {
            newValues[newIndex] = undefined;
            oldValues[oldIndex] = undefined;
          }
        });
      });
      newValues = _.reject(newValues, (newValue) => newValue === undefined);
      oldValues = _.reject(oldValues, (oldValue) => oldValue === undefined);
      if (
        newValues.length === oldValues.length &&
        newValues.length === values.length
      ) {
        return values;
      }
      if (newValues.length === oldValues.length && newValues.length !== 0) {
        _.each(newValues, (newValue, newIndex) => {
          const obj = _.cloneDeep(temp);
          obj.newValue = newValue;
          obj.oldValue = oldValues[newIndex];
          list.push(obj);
        });
      }
      return list;
    };
    _.each(this.historyList, (historyItem) => {
      const companyCodeValues = _.filter(historyItem.value, {
        formType: "Supplier & Spend Details",
        columName: "CompanyCode",
        status: "Updated",
      });
      const paymentTermValues = _.filter(historyItem.value, {
        formType: "Supplier & Spend Details",
        columName: "TimeLimit",
        status: "Updated",
      });
      historyItem.value = _.concat(
        _.reject(historyItem.value, ({ formType, columName, status }) => {
          return (
            (formType === "Supplier & Spend Details" &&
              columName === "CompanyCode" &&
              status === "Updated") ||
            (formType === "Supplier & Spend Details" &&
              columName === "TimeLimit" &&
              status === "Updated")
          );
        }),
        distinctList(companyCodeValues),
        distinctList(paymentTermValues)
      );
    });
    this.historyList = _.reject(
      this.historyList,(historyItem) => {
        historyItem.value.length === 0;
        this.loadingService.hideLoading(); 
      }
    );
    this.setConfigMap();
  }
  setConfigMap() {
    for (const his of this.historyList) {
      const getConfigsInfo = this.setConfigsInfo(his.value, his.date);
      this.configMap.set(his.txId, getConfigsInfo);
      his["config"] = this.getConfigsInfo(his);
    }
    this.loaded = true;
  }

  setConfigsInfo(history, hisDate): any {
    const array = [];
    const obj = this;
    this.context.set("history", history);
    this.bankCountry = "";
    this.thirdPartyBankCountry = "";
    this.IntermediaryBankCountry = "";
    this.registeredCountry = "";
    this.AddGSTCertificateAttachments = "";
    history.forEach((item) => {
      if (
        (!item.newValue &&
          !item.oldValue &&
          !item.newFiles &&
          !item.oldFiles) ||
        (item.isFile &&
          item.newValue &&
          !item.newValue.files.length &&
          !item.oldValue &&
          !item.newFiles.length &&
          !item.oldFiles)
      ) {
        return;
      }
      obj.context.set("item", item);
      let filters_data = [];
      let hook_data = [];
      let option_data = [];
      let dataSource_data = {};

      if (item.filters) {
        filters_data = item.filters;
      }
      if (item.hooks) {
        hook_data = item.hooks;
      }
      if (item.options) {
        option_data = hook_data;
      }
      if (item.dataSource) {
        dataSource_data = item.dataSource;
      }
      if (
        history[0].status === "Updated" &&
        (!item.oldValue ||
          (item.isFile && item.oldValue.files && !item.oldValue.files.length))
      ) {
        item.oldValue = environment.languageSwitch
          ? this.languageService.getValue("static.history.blank")
          : "Blank";
        item.status = "Updated";
      }

      if (item.status === "Updated" && item.oldValue === "Blank") {
        item.oldValue = environment.languageSwitch
          ? this.languageService.getValue("static.history.blank")
          : "Blank";
      }

      if (item.status === "Updated" && item.newValue === "Blank") {
        item.newValue = environment.languageSwitch
          ? this.languageService.getValue("static.history.blank")
          : "Blank";
      }

      if (item.status === "Updated" && item.newValue === item.oldValue) {
        return;
      }

      if (item.hide) {
        console.log(item);
      }
      const column = {
        name: item.columName,
        type: "item",
        width: "100%",
        languageKey: item.languageKey,
        label: item.title,
        templateOptions: item,
        filters: filters_data,
        hooks: hook_data,
        options: option_data,
        dataSource: dataSource_data,
        hide: item.hide,
      };
      //if it is payment term's company code,modify its label to distinguish the common company code.
      if (
        item.columName == "CompanyCode" &&
        item.formType === "Supplier & Spend Details"
      ) {
        column.label = "PaymentTermList-CompanyCode";
      }

      if (column.name === "BankCountry") {
        this.bankCountry = item.newValue;
      }
      if (column.name === "BankKey") {
        if (this.bankCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Financial Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "BankCountry"
              );
              if (elementTemp) {
                this.bankCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.bankCountry) {
          case "AU":
          case "NZ":
            column.label = "Bank State Branch Number";
            column.languageKey = "static.history.BankKey_AU_NZ_label";
            break;
          case "IE":
          case "GB":
            column.label = "Sort Code";
            column.languageKey = "static.history.BankKey_IE_GB_label";
            break;
          default:
            column.label = "Bank Branch Code";
            column.languageKey = "static.history.BankKey_Default_label";
            break;
        }
      }
      if (column.name === "ThirdPartyBankCountry") {
        this.thirdPartyBankCountry = item.newValue;
      }
      if (column.name === "ThirdPartyBankKey") {
        if (this.thirdPartyBankCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Financial Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "ThirdPartyBankCountry"
              );
              if (elementTemp) {
                this.thirdPartyBankCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.thirdPartyBankCountry) {
          case "AU":
          case "NZ":
            column.label = "Third-Party Bank State Branch Number";
            column.languageKey = "static.history.ThirdPartyBankKey_AU_NZ_label";
            break;
          case "IE":
          case "GB":
            column.label = "Third-Party Sort Code";
            column.languageKey = "static.history.ThirdPartyBankKey_IE_GB_label";
            break;
          default:
            column.label = "Third-Party Bank Branch Code";
            column.languageKey =
              "static.history.ThirdPartyBankKey_Default_label";
            break;
        }
      }
      if (column.name === "IntermediaryBankCountry") {
        this.IntermediaryBankCountry = item.newValue;
      }
      if (column.name === "IntermediaryBankKey") {
        if (this.IntermediaryBankCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Financial Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "IntermediaryBankCountry"
              );
              if (elementTemp) {
                this.IntermediaryBankCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.IntermediaryBankCountry) {
          case "AU":
          case "NZ":
            column.label = "Intermediary Bank State Branch Number";
            column.languageKey = "static.history.AU_NZ_label";
            break;
          case "IE":
          case "GB":
            column.label = "Intermediary Sort Code";
            column.languageKey = "static.history.IE_GB_label";
            break;
          default:
            column.label = "Intermediary Bank Branch Code";
            column.languageKey = "static.history.Default_label";
            break;
        }
      }
      if (column.name === "SupplierRegisteredCountry") {
        this.registeredCountry = item.newValue;
      }
      if (column.name === "BusinessRegistrationNb") {
        if (this.registeredCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Organization Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "SupplierRegisteredCountry"
              );
              if (elementTemp) {
                this.registeredCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.registeredCountry) {
          case "DK":
          case "FI":
          case "LV":
          case "NO":
          case "SE":
            column.label = "Company VAT Number";
            column.languageKey =
              "static.history.SupplierRegisteredCountryDK_FI_LV_label";
            break;
          case "CH":
            column.label = "UID Number";
            column.languageKey =
              "static.history.SupplierRegisteredCountryCH_label";
            break;
        }
      }
      if (column.name === "Country") {
        this.country = item.newValue;
      }
      if (
        (this.country === "Denmark" ||
          this.country === "Finland" ||
          this.country === "Latvia" ||
          this.country === "Norway" ||
          this.country === "Sweden") &&
        column.name === "TermPayment"
      ) {
        this.termPayment = item.newValue;
        switch (this.termPayment) {
          case "ZN30":
          case "ZN40":
          case "ZN45":
            column.label = "Please select an alternative Payment Term";
            column.languageKey = "static.history.NOT_Nordics_label";
            break;
          default:
            column.label =
              "Please select the Non-Standard Payment Term agreed and upload evidence you have approval to use this:";
            column.languageKey = "static.history.Has_No_Result_label";
            break;
        }
      }
      if (column.name === "ABARouting") {
        if (this.bankCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Financial Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "BankCountry"
              );
              if (elementTemp) {
                this.bankCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.bankCountry) {
          case "US":
          case "PR":
            column.label = "ABA Routing Number (ACH)";
            column.languageKey =
              "static.history.ABARouting_templateOptions_label";
            break;
          default:
            column.label = "ABA Routing Code/Sort Key/IFSC";
            column.languageKey = "static.history.ABARouting_label";
            break;
        }
      }
      if (column.name === "ThirdPartyABARouting") {
        if (this.thirdPartyBankCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Financial Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "ThirdPartyBankCountry"
              );
              if (elementTemp) {
                this.thirdPartyBankCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.thirdPartyBankCountry) {
          case "US":
          case "PR":
            column.label = "Third-Party ACH ABA Routing Number";
            column.languageKey =
              "static.history.ThirdPartyABARouting_templateOptions_label";
            break;
          default:
            column.label = "Third-Party ABA Routing Code/Sort Key/IFSC";
            column.languageKey = "static.history.ThirdPartyABARouting_label";
            break;
        }
      }
      if (column.name === "IntermediaryABARouting") {
        if (this.IntermediaryBankCountry === "" && item.status === "Updated") {
          this.historyList
            .filter(
              (h) =>
                h.typeInfo.includes("Financial Information") &&
                new Date(h.date) < new Date(hisDate)
            )
            .some((element) => {
              const elementTemp = element.value.find(
                (v) => v.columName === "IntermediaryBankCountry"
              );
              if (elementTemp) {
                this.IntermediaryBankCountry = elementTemp.newValue;
                return true;
              }
            });
        }
        switch (this.IntermediaryBankCountry) {
          case "US":
          case "PR":
            column.label = "Intermediary ABA Routing Number (ACH)";
            column.languageKey =
              "static.history.IntermediaryABARouting_templateOptions_label";
            break;
          default:
            column.label = "Intermediary ABA Routing Code/Sort Key/IFSC";
            column.languageKey = "static.history.IntermediaryABARouting_label";
            break;
        }
      }
      if (
        column.name === "UploadRegistrationDocuments" &&
        ((!!this.registeredCountry &&
          !(
            this.registeredCountry === "BG" ||
            this.registeredCountry === "HR" ||
            this.registeredCountry === "CZ" ||
            this.registeredCountry === "GR" ||
            this.registeredCountry === "HU" ||
            this.registeredCountry === "PL" ||
            this.registeredCountry === "RO" ||
            this.registeredCountry === "AE" ||
            this.registeredCountry === "SA" ||
            this.registeredCountry === "QA" ||
            this.registeredCountry === "BH" ||
            this.registeredCountry === "EG" ||
            this.registeredCountry === "AT" ||
            this.registeredCountry === "CH" ||
            this.registeredCountry === "DE" ||
            this.registeredCountry === "BG"
          ) &&
          this.context.getValue("isMET")) ||
          (!!this.registeredCountry &&
            !(
              this.registeredCountry === "AT" ||
              this.registeredCountry === "CH" ||
              this.registeredCountry === "DE" ||
              this.registeredCountry === "BG" ||
              this.registeredCountry === "HR " ||
              this.registeredCountry === "CZ" ||
              this.registeredCountry === "GR" ||
              this.registeredCountry === "HU" ||
              this.registeredCountry === "PL" ||
              this.registeredCountry === "RO"
            ) &&
            (this.context.getValue("isUKIA") ||
              this.context.getValue("isGallia") ||
              this.context.getValue("isJapan"))))
      ) {
        column.label =
          "Please upload evidence of your company’s VAT or Business Registration Number";
        column.languageKey =
          "static.history.UploadRegistrationDocuments_templateOptions_label";
      } else if (
        column.name === "UploadRegistrationDocuments" &&
        (this.registeredCountry === "DK" ||
          this.registeredCountry === "FI" ||
          this.registeredCountry === "LV" ||
          this.registeredCountry === "EE" ||
          this.registeredCountry === "NO" ||
          this.registeredCountry === "SE")
      ) {
        column.label = "Please upload evidence of your VAT Number";
        column.languageKey = "static.history.SupplierRegisteredCountry_DK_FI";
      }
      if (
        column.name === "BusinessRegistrationNb" &&
        ((!!this.registeredCountry &&
          !(
            this.registeredCountry === "BG" ||
            this.registeredCountry === "HR" ||
            this.registeredCountry === "CZ" ||
            this.registeredCountry === "GR" ||
            this.registeredCountry === "HU" ||
            this.registeredCountry === "PL" ||
            this.registeredCountry === "RO" ||
            this.registeredCountry === "AE" ||
            this.registeredCountry === "SA" ||
            this.registeredCountry === "QA" ||
            this.registeredCountry === "BH" ||
            this.registeredCountry === "EG" ||
            this.registeredCountry === "AT" ||
            this.registeredCountry === "CH" ||
            this.registeredCountry === "DE" ||
            this.registeredCountry === "BG"
          ) &&
          this.context.getValue("isMET")) ||
          (!!this.registeredCountry &&
            !(
              this.registeredCountry === "AT" ||
              this.registeredCountry === "CH" ||
              this.registeredCountry === "DE" ||
              this.registeredCountry === "BG" ||
              this.registeredCountry === "HR " ||
              this.registeredCountry === "CZ" ||
              this.registeredCountry === "GR" ||
              this.registeredCountry === "HU" ||
              this.registeredCountry === "PL" ||
              this.registeredCountry === "RO"
            ) &&
            (this.context.getValue("isUKIA") ||
              this.context.getValue("isGallia") ||
              this.context.getValue("isJapan"))))
      ) {
        column.label = "Business Registration/Identification Number";
        column.languageKey = "static.history.isMETOrUKIAOrAfrica_label";
      }
      // eslint-disable-next-line max-len
      if (
        column.name === "BusinessRegistrationNb" &&
        this.context
          .getValue("parentModel")
          .RequestForm.RequestorDetailsInfo.SupplierCountry.find(
            (P) => P === "Ca"
          ) &&
        (this.context.getValue("parentModel").SupplierProfile
          .SupplierOrganization.SupplierRegisteredCountry !== "Ca" ||
          this.context.getValue("parentModel").SupplierProfile
            .SupplierOrganization.SupplierRegisteredCountry !== "US" ||
          this.context.getValue("parentModel").SupplierProfile
            .SupplierOrganization.SupplierRegisteredCountry !== "PR")
      ) {
        column.label =
          "VAT/Registration Number/Tax Identification Number (Local country Tax ID)";
        column.languageKey = "static.history.US_PR_label";
      }
      if (column.name === "AdditionalGSTVendor") {
        this.AddGSTCertificateAttachments = item.newValue;
      }

      if (column.name === "AddGSTCertificateAttachments") {
        if (
          this.AddGSTCertificateAttachments === "" &&
          item.status === "Updated"
        ) {
          let elementTemp = history.find(
            (historyItem) =>
              historyItem.tagId === item.tagId &&
              historyItem.columName === "AdditionalGSTVendor"
          );
          if (elementTemp) {
            this.AddGSTCertificateAttachments = elementTemp.newValue;
          } else {
            this.historyList
              .filter(
                (list) =>
                  list.typeInfo.includes("Organization Information") &&
                  new Date(list.date) < new Date(hisDate)
              )
              .some((element) => {
                elementTemp = element.value.find(
                  (elementItem) =>
                    elementItem.tagId === item.tagId &&
                    elementItem.columName === "AdditionalGSTVendor"
                );
                if (elementTemp) {
                  this.AddGSTCertificateAttachments = elementTemp.newValue;
                  return true;
                }
              });
          }
        }
        if (
          this.AddGSTCertificateAttachments &&
          this.AddGSTCertificateAttachments.includes("04")
        ) {
          column.label =
            "Please upload letterhead declaration for Unregistered GST classification";
          column.languageKey = "static.history.Result_04_label";
        } else {
          column.label =
            "Please upload your Company's GST Registration Certificate for additional state registration";
          column.languageKey = "static.history.historyLabel";
        }
      }
      array.push(column);
    });

    return array;
  }

  getConfigsInfo(history) {
    if (this.configMap.get(history.txId)) {
      return this.configMap.get(history.txId);
    } else {
      const getConfigsInfo = this.setConfigsInfo(history.value, history.date);
      this.configMap.set(history.txId, getConfigsInfo);
    }
  }

  setParentModelContext(supplierProfile: any) {
    const currentParentModel = this.context.get("parentModel").value;
    const mergedParentModel = _.cloneDeep(currentParentModel);
    mergedParentModel.SupplierProfile = supplierProfile;
    if (supplierProfile && currentParentModel) {
      this.context.set("parentModel", mergedParentModel);
    }
  }

  toggle(history: any, index: number) {
    let configs: any[];
    let currentSupplierProfile: any;
    let previewSupplierProfile: any;
    if (history.config && history.config.length !== 0) {
      configs = _.cloneDeep(history.config);
    }
    if (
      !history.show &&
      this.supplierProfiles &&
      this.supplierProfiles.length !== 0
    ) {
      const supplierProfileIndex = this.supplierProfiles.length - 1 - index;
      currentSupplierProfile = this.supplierProfiles[supplierProfileIndex].data;
      if (supplierProfileIndex !== 0) {
        previewSupplierProfile =
          this.supplierProfiles[supplierProfileIndex - 1].data;
      }
      this.setParentModelContext(currentSupplierProfile);
    }
    history.show = !history.show;
    setTimeout(() => {
      if (
        _.filter(
          configs,
          ({ name }) =>
            (_.find(history.value, { columName: name }) || {}).fieldType ===
            "uploader"
        ).length === 0
      ) {
        return;
      }
      this.setParentModelContext(previewSupplierProfile);
      // history.config = _.concat(history.config, history.config[0]);
      setTimeout(() => {
        const currentConfigs = _.map(configs, (config) => {
          const uploaderConfig = _.find(history.value, {
            columName: config.name,
          });
          if (!!config.templateOptions.isFile) {
            if (config.templateOptions.newFiles.length === 0||config.templateOptions.newValue?.files?.length === 0) {
              let newFiles = [{
                name: environment.languageSwitch ? this.languageService.getValue("static.history.blank") : "Blank",
                key: '',
                path: ''
              }]
              config.templateOptions.newFiles = newFiles;
            }
          }
          if (uploaderConfig && config.templateOptions.isFile && config.templateOptions?.oldFile.length === 0) {
            delete config.templateOptions.oldFiles;
            config.templateOptions.oldValue = environment.languageSwitch
              ? this.languageService.getValue("static.history.blank")
              : "Blank";
          }
          history.value.forEach(historyitem => {
            if(historyitem.status == 'Updated' && historyitem.oldValue == "") {
              config.templateOptions.oldValue =  "Blank"
            }
          })
          return config;
        });
        this.setParentModelContext(currentSupplierProfile);
        history.config = currentConfigs;
      });
    });
  }
}
